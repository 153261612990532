:root {
  --border-radius-base: 0.4rem;
  --border-radius-wide: 0.8rem;
}

.SkeletonShimmer {
  position: relative;
  background-color: var(--p-surface-neutral);
  animation: var(--polaris-animation-skeleton-shimmer) 800ms linear infinite
    alternate;
  backface-visibility: hidden;
  will-change: opacity;
}

[id^='AppPortal-'] {
  position: relative;
  z-index: 10000;
}
